<template>
    <!-- 舟山友联 -->
    <div id="CustomerDZMotor">
        <!-- CustomerDZMotor头部内容 -->
        <div class="CustomerDZMotorHeader">
            <!-- 头部组件 -->
            <Header />
        </div>
        <!-- CustomerDZMotor中间内容 -->
        <div class="CustomerDZMotorCenter">
            <!-- 客户介绍 -->
            <div class="contentOne">
                <div class="titleOne">客户介绍</div>
                <div class="conInfo">
                    <img src="~assets/img/CustomerYL/公司@2x.png" />
                    <div class="infoOne">
                        &#160;&#160;&#160;&#160;&#160;浙江友联修造船有限公司（简称舟山友联）,原名浙江东邦修造船有限公司，成立于2007年5月30日，位于岱山县衢山镇岛扎村潮头门岙，距上海国际航运中心洋山深水港区仅15海里，以各类船舶修理、船舶改装以及海洋工程为主营业务。2018年4月1日招商工业集团正式接管浙江友联。其正式更名为浙江友联修造船有限公司。<br /><br />

                    </div>
                </div>
            </div>
            <!-- 项目内容 -->
            <div class="contentTwo">
                <div class="titleTwo">项目内容</div>
                <div class="titleT">
                    投入26台套清洗机器人及相关配套生产设备，实施修船一单制管理系统、
                    修船全生命周期管理系统、资产管理系统、供应链管理系统、安全管理系统、能源管理系统等软件系统，
                    建设企业级工业互联网数据中台及可视化看板等，实现数据互联互通，建成修造船智能工厂。
                </div>
            </div>
            <!-- 项目成效 -->
            <div class="contentTwo" style="margin-top: 20px;">
                <div class="titleTwo">项目成效</div>
                <div class="titleT">
                    ①浙江友联2023年尤其是营收和利润同比有较大幅度提升，这与浙江友联转换生产方式，推行绿色、智能、科技修船存在关联性，带动具有社会责任感的全球大型航运公司与浙江友联开展业务合作，推动业务增长和利润率提升。<br />
                    ②彻底解决传统冲砂导致的环境污染问题，除锈作业过程中环境污染接近0。<br />
                    ③降低作业过程中的高处坠落安全风险系数50%以上。<br />
                    ④提升劳动生产率2倍。<br />
                    ⑤降低船舶除锈成本。<br />
                    <el-icon class="el-icon-trophy" style="color: #ffc24c;font-size: 18px;"></el-icon> 舟山市数字化标杆项目 <br />
                    <el-icon class="el-icon-trophy" style="color: #ffc24c;font-size: 18px;"></el-icon>     2023年度市级数字工厂 
                </div>
            </div>

            <!-- 项目概况 -->
            <div class="contentThree" >
                <!-- <div class="titleThree">项目概况</div> -->
                <ul class="imgContent">
                    <li v-for="(item, index) in imgContents" :key="index">
                        <img :src="item.Imgs" style="width: 550px;height: 324px;" />
                        <!-- <div class="imgBottom">{{ item.textPro }}</div> -->
                    </li>
                </ul>
            </div>
        </div>
        <!-- 底部内容 -->
        <Footer />
        <!-- 回到顶部 -->
        <el-backtop><img src="~assets/img/CustomerDZMotor/icon-一键置顶@2x.png" alt="icon-回到顶部@2x.png" /></el-backtop>
    </div>
</template>
<script>
import Header from "components/Header/dsHeader";
import Footer from "components/Footer/dsFooter";
export default {
    name: "CustomerDZMotor",
    data() {
        return {
            imgContents: [
                {
                    Imgs: require("assets/img/board/yl/01.png"),
                    textPro: ""
                },{
                    Imgs: require("assets/img/board/yl/02.png"),
                    textPro: ""
                },{
                    Imgs: require("assets/img/board/yl/03.png"),
                    textPro: ""
                },{
                    Imgs: require("assets/img/board/yl/04.png"),
                    textPro: ""
                }
            ],

        };
    },
    mounted() {  
      document.title = '岱山汽船配工业互联网平台';   
  },
    components: {
        Header,
        Footer
    }
};
</script>
<style lang="less" scope>
#CustomerDZMotor {
  width: 1583px;
  background-color: #f8f9fb;
  margin: 0 auto;

  // CusCustomerDZMotor头部样式
  .CustomerDZMotorHeader {
    width: 1583px;
    height: 620px;
    background: url("~assets/img/CustomerYL/banner@2x.jpg") no-repeat;
    background-size: 1583px 620px;
    background-position: center;
    margin: 0 auto;
  }

  // CustomerDZMotor中间内容样式
  .CustomerDZMotorCenter {
    width: 1220px;
    margin: 80px auto 101px;

    // 客户介绍
    .contentOne {
      .titleOne {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
      }

      .conInfo {
        margin-top: 30px;
        display: flex;

        img {
          width: 560px;
          height: 343px;
        }

        .infoOne {
          width: 590px;
          height: 157px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 28px;
          color: rgba(51, 51, 51, 0.85);
          margin: 3px 0 0 40px;
        }
      }
    }

    // 项目价值
    .contentTwo {
      margin-top: 60px;

      .titleTwo {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
      }

      .titleT {
        width: 1200px;
        // height: 72px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 28px;
        color: rgba(51, 51, 51, 0.85);
        margin-top: 30px;
      }
    }

    // 项目概况
    .contentThree {

      // margin-top: 60px;
      .titleThree {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }

      .imgContent {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        // margin-top: 30px;
        padding: 0;

        li {
          margin-bottom: 30px;
          background-color: #fff;



          .imgBottom {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: rgba(51, 51, 51, 0.85);
            margin-top: 15px;
            text-align: center;
          }
        }
      }
    }
  }
}</style>
<style lang="less">.el-backtop {
    img {
        width: 30px;
        height: 30px;
    }
}</style>
  